'use client';

import { m } from 'framer-motion';

import { Button, Typography } from '@mui/material';
import { CompactLayout } from '../layouts/compact';
import { MotionContainer, varBounce } from '../animate';
import PageNotFoundIllustration from '@/assetes/illustrations/page-not-found-illustration';
import { RouterLink } from '../router-link/router-link';

export const NotFoundView = () => (
  <CompactLayout>
    <MotionContainer>
      <m.div variants={varBounce().in}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Sorry, Page Not Found!
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <Typography sx={{ color: 'text.secondary' }}>
          Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be
          sure to check your spelling.
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <PageNotFoundIllustration
          sx={{
            height: 260,
            my: { xs: 5, sm: 10 },
          }}
        />
      </m.div>

      <Button component={RouterLink} href="/" size="large" variant="contained">
        Go to Home
      </Button>
    </MotionContainer>
  </CompactLayout>
);
